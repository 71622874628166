import React from 'react';
import Page from './page';
import { COLORS } from '../../constants';
import { NewPageSection } from '../../components/new-page-section';
import { SectionSubtitle } from '../../components/section-subtitle';
import { Text } from '../../components/text';
import SurveyResearchQualtrics1 from '../../images/boat1.svg';
import SurveyResearchQualtrics2 from '../../images/boat2.svg';
import { ImageContainer } from '../../components/image-container';
import { ShowcaseCard } from '../../components/showcase-card';
import google from '../../images/google.svg';
import apple from '../../images/apple.svg';
import rci from '../../images/rci.svg';
import ford from '../../images/ford.svg';
import { PageTitle } from '../../components/page-title';
import { Spacing } from '../../components/spacing';
import betaDomainModel from '../../images/domainModel.png';
import siteMap from '../../images/siteMap.png';
import parkNavPoster from '../../images/parkNavPoster.svg';
import lizMartin1 from '../../images/lizMartin1.svg';
import lizMartin2 from '../../images/lizMartin2.svg';
import { Link } from 'gatsby';
import hifi4 from '../../images/hifi4.svg';

export function Showcase({ refElement }) {
  return (
    <Page id="showcase" refElement={refElement}>
      <Spacing size="large">
        <PageTitle color={COLORS.showcase}>Showcase</PageTitle>
      </Spacing>

      <Spacing size="large">
        <NewPageSection title="Research" color={COLORS.showcase}>
          <Spacing size="medium">
            From undergraduate sociology research, to managing survey research
            projects at Qualtrics, to master’s level user research projects - I
            have had a hand in a myriad of research activities. Here are some
            excerpts of my work:
          </Spacing>

          <Spacing size="medium">
            <SectionSubtitle>
              UX Research & Research Deliverables -{' '}
              <Link to="/parknav">
                <Text emphasis={COLORS.showcase}>ParkNav</Text>
              </Link>
            </SectionSubtitle>
            <Spacing size="small">
              ParkNav is an app & kiosk concept reimagining the way we interact
              with technology and information finding in parks. Using Regent’s
              Park in London as our reference, a small team of fellow HCI Design
              students and I conducted{' '}
              <Text emphasis={COLORS.showcase}>
                onsite observation sessions, interviews, and online survey
                research
              </Text>{' '}
              in order to understand the park users, their needs, and our design
              goals. After gathering the data, my team and I built out{' '}
              <Text emphasis={COLORS.showcase}>
                UX personas and user journeys
              </Text>
              , using the real data as a reference. This research and these
              research deliverables proved instrumental in our later design of
              the product interfaces, as we frequently referenced them and asked
              ourselves,
              <Text emphasis={COLORS.showcase}>“What would our user do?”</Text>
            </Spacing>

            <ImageContainer
              images={[lizMartin1, lizMartin2]}
              alts={['Persona', 'User Journey']}
              clickable
            />
          </Spacing>

          <Spacing size="medium">
            <SectionSubtitle>Survey Research - Qualtrics</SectionSubtitle>
            <Spacing size="small">
              With nearly three years of experience as a Research Project
              Manager at Qualtrics under my belt as well as a background in
              social research from undergrad, I have a strong grasp of{' '}
              <Text emphasis={COLORS.showcase}>
                survey building software, survey design methodology, survey
                research project management (including fielding and panels) and
                the benefits and limitations of online survey research.
              </Text>{' '}
              Here are some of the projects I have worked on:
            </Spacing>

            <ImageContainer
              images={[SurveyResearchQualtrics1, SurveyResearchQualtrics2]}
              alts={[
                'Measuring changing attitudes towards cruising during the pandemic',
                '"Testing demand for a tool..." and "Finding market usage of..."',
              ]}
              clickable
            />
          </Spacing>

          <SectionSubtitle>
            Information Architecture -{' '}
            <Link to="/designing-for-community-bands">
              <Text emphasis={COLORS.showcase}>Orchestrate</Text>
            </Link>
          </SectionSubtitle>
          <Spacing size="small">
            A work-in-progress project is my design of an informative website on
            community bands’ information architecture structure. In order to get
            to the design stage, I first needed to conduct research to
            understand the subject. I did this through{' '}
            <Text emphasis={COLORS.showcase}>
              semi-structured inteviews with domain experts
            </Text>
            , which I then used to build a{' '}
            <Text emphasis={COLORS.showcase}>domain model and sitemap</Text>.
          </Spacing>
          <ImageContainer
            images={[betaDomainModel, siteMap]}
            alts={['Beta Domain Model', 'Site Map']}
            clickable
          />
        </NewPageSection>
      </Spacing>

      <Spacing size="large">
        <NewPageSection title="Design" color={COLORS.showcase}>
          <Spacing size="medium">
            My design process is very{' '}
            <Text emphasis={COLORS.showcase}>iterative and collaborative</Text>{' '}
            - I like to thoroughly brainstorm ideas with my team, then start
            designing on paper with sharpies and move up from there, improving
            the design as I get more feedback from users, collaborators, and
            experts. While I am newer to the design scene, I have nonetheless
            grown substantially since I first started putting “pointer to
            pixel.”
          </Spacing>

          <Spacing size="medium">
            <ShowcaseCard navigateTo="/parknav">
              <SectionSubtitle>ParkNav</SectionSubtitle>
              <Spacing size="small">
                Reimagining the way we interact with technology and information
                finding in parks
              </Spacing>
              <ImageContainer
                images={[parkNavPoster]}
                alts={['ParkNav poster']}
              />
            </ShowcaseCard>
          </Spacing>

          <Spacing size="medium">
            <ShowcaseCard navigateTo="/designing-for-community-bands">
              <SectionSubtitle>Orchestrate</SectionSubtitle>
              <Spacing size="small">
                A Work in Progress: Designing a community for community bands
              </Spacing>
              <ImageContainer
                images={[betaDomainModel]}
                alts={['Domain model']}
              />
            </ShowcaseCard>
          </Spacing>

          <ShowcaseCard navigateTo="/rethinking-recruiting">
            <SectionSubtitle>Rethinking Recruiting</SectionSubtitle>
            <Spacing size="small">
              An old project I created in order to teach myself Figma and the UX
              design process
            </Spacing>
            <ImageContainer
              images={[hifi4]}
              alts={['Rethinking Recruiting screenshot']}
              limitWidth={400}
            />
          </ShowcaseCard>
        </NewPageSection>
      </Spacing>

      <Spacing size="large">
        <NewPageSection title="Evaluation" color={COLORS.showcase}>
          Good user-centered design goes hand-in-hand with evaluation. I have
          experience in:
          <ul>
            <li>
              Conducting in-person{' '}
              <Text emphasis={COLORS.showcase}>usability tests</Text> using{' '}
              <Text emphasis={COLORS.showcase}>user tasks</Text> and{' '}
              <Text emphasis={COLORS.showcase}>Figma prototypes</Text> in order
              to understand usability issues and get general feedback
            </li>
            <li>
              Reviewing webpages for{' '}
              <Text emphasis={COLORS.showcase}>accessibility</Text> issues and
              suggesting changes (relying heavily on{' '}
              <Text emphasis={COLORS.showcase}>WCAG 2.1 guidelines</Text>)
            </li>
            <li>
              Conducting{' '}
              <Text emphasis={COLORS.showcase}>
                card sorting and tree test evaluations
              </Text>{' '}
              on the information architecture design of a website, to ensure
              that information and page layout is organized in a sensible
              manner.
            </li>
            <li>
              Sitting in on UserZoom sessions to evaluate a redesign of
              Qualtrics’ ticketing dashboard
            </li>
          </ul>
        </NewPageSection>
      </Spacing>

      <NewPageSection title="Project Management" color={COLORS.showcase}>
        During my almost 3 years at Qualtrics, I gained professional experience
        in a variety of project management skills:
        <Spacing size="medium">
          <ul>
            <li>
              <Text emphasis={COLORS.showcase}>Owning communication</Text>{' '}
              across clients, partners, internal teams, and other stakeholders
            </li>
            <li>
              <Text emphasis={COLORS.showcase}>Making budget decisions</Text> to
              optimize for project, business, and client success
            </li>
            <li>
              <Text emphasis={COLORS.showcase}>
                Sticking to the project timeline
              </Text>
              , finding creative solutions to make up for lost time when delays
              are introduced without sacrificing quality
            </li>
            <li>
              <Text emphasis={COLORS.showcase}>Taking responsibility</Text> for
              every project at each stage of the project cycle
            </li>
            <li>
              Utilizing my resources to{' '}
              <Text emphasis={COLORS.showcase}>proactively solve issues</Text>{' '}
              as they arise,
              <Text emphasis={COLORS.showcase}>delegating</Text> to the
              appropriate internal parties when necessary{' '}
            </li>
            <li>
              <Text emphasis={COLORS.showcase}>Working as a team</Text> with
              adjacent coworkers on the project, ensuring we are all aligned and
              that they have what they need to be successful in their respective
              roles
            </li>
            <li>
              Maintaining <Text emphasis={COLORS.showcase}>organization</Text>
            </li>
          </ul>
        </Spacing>
        <Spacing size="small">
          I’ve been fortunate enough to work on several hundred projects, many
          of which have contributed to fascinating real-world insights. Here are
          some of the top brands I have worked with:
        </Spacing>
        <ImageContainer
          images={[google, apple, rci, ford]}
          alts={['Google', 'Apple', 'Royal Caribbean International', 'Ford']}
        />
      </NewPageSection>
    </Page>
  );
}

export default Showcase;
