import { Box, Paper } from '@mui/material';
import React from 'react';
import { COLORS } from '../constants';
import { Link } from 'gatsby';

export function ShowcaseCard({ navigateTo, children }) {
  return (
    <Box sx={{ maxWidth: '75%', margin: 'auto' }}>
      <Link to={navigateTo}>
        <Paper
          sx={{
            border: `2px solid ${COLORS.showcase}`,
            padding: 2,
            cursor: 'pointer',
          }}
        >
          {children}
        </Paper>
      </Link>
    </Box>
  );
}
